import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "wm-msg_content flex" }
const _hoisted_2 = { class: "wm-msg_left" }
const _hoisted_3 = { class: "wm-msg_uinfo flex" }
const _hoisted_4 = {
  key: 0,
  class: "ui ui_image"
}
const _hoisted_5 = { class: "name nowrap" }
const _hoisted_6 = {
  key: 0,
  class: "config"
}
const _hoisted_7 = { class: "wm-msg_search" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "user_list_body"
}
const _hoisted_10 = { class: "user_list" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "ui ui_image"
}
const _hoisted_13 = { class: "name nowrap" }
const _hoisted_14 = {
  key: 1,
  class: "null"
}
const _hoisted_15 = { class: "wm-msg_left_ct scrollbar" }
const _hoisted_16 = {
  key: 0,
  class: "wm-msg_list"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 0,
  class: "redNum"
}
const _hoisted_19 = {
  key: 1,
  class: "ui ui_image"
}
const _hoisted_20 = { class: "msg_text" }
const _hoisted_21 = { class: "flex" }
const _hoisted_22 = { class: "title nowrap" }
const _hoisted_23 = { class: "time" }
const _hoisted_24 = { class: "text nowrap" }
const _hoisted_25 = {
  key: 1,
  class: "msg_null"
}
const _hoisted_26 = { class: "wm-msg_right" }
const _hoisted_27 = { class: "wm-msg_title" }
const _hoisted_28 = { class: "wm-msg_ct scrollbar" }
const _hoisted_29 = { class: "time" }
const _hoisted_30 = {
  key: 0,
  class: "msg_left flex_left"
}
const _hoisted_31 = {
  key: 0,
  class: "ui ui_image"
}
const _hoisted_32 = { class: "msg_body flex_left" }
const _hoisted_33 = { class: "content" }
const _hoisted_34 = {
  key: 0,
  class: "red"
}
const _hoisted_35 = {
  key: 1,
  class: "msg_right flex_right"
}
const _hoisted_36 = { class: "msg_body flex_right" }
const _hoisted_37 = { class: "content" }
const _hoisted_38 = {
  key: 0,
  class: "red"
}
const _hoisted_39 = {
  key: 0,
  class: "ui ui_image"
}
const _hoisted_40 = {
  key: 1,
  class: "msg_null"
}
const _hoisted_41 = { class: "wm-msg_action flex_right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wm_button = _resolveComponent("wm-button")!
  const _component_wm_popup = _resolveComponent("wm-popup")!

  return (_openBlock(), _createElementBlock("div", {
    class: "wm-msg_body",
    style: _normalizeStyle({visibility:_ctx.msgShow?'inherit':'hidden'})
  }, [
    _createVNode(_component_wm_popup, {
      width: "980px",
      height: "700px",
      show: _ctx.msgShow,
      "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.msgShow) = $event)),
      onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.close()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "img",
                style: _normalizeStyle({backgroundImage: this.state.uinfo.img?'url('+this.state.uinfo.img+')':''})
              }, [
                (!this.state.uinfo.img)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                  : _createCommentVNode("", true)
              ], 4),
              _createElementVNode("div", _hoisted_5, _toDisplayString(this.state.uinfo.nickname), 1),
              _createElementVNode("div", {
                class: "more",
                tabindex: "0",
                onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.more=false)),
                onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.more=true))
              }, [
                _cache[15] || (_cache[15] = _createElementVNode("i", { class: "ui ui_more" }, null, -1)),
                (_ctx.more)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                      _createElementVNode("li", {
                        class: "flex_left",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.isUinfo=true))
                      }, [
                        _cache[13] || (_cache[13] = _createElementVNode("i", { class: "ui ui_user" }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.state.langs.uinfo_title), 1)
                      ]),
                      _createElementVNode("li", {
                        class: "flex_left",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.isPasswd=true))
                      }, [
                        _cache[14] || (_cache[14] = _createElementVNode("i", { class: "ui ui_safety" }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.state.langs.passwd_title), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 32)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[17] || (_cache[17] = _createElementVNode("i", { class: "sea ui ui_search" }, null, -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sea.key) = $event)),
                placeholder: _ctx.state.langs.msg_seach,
                onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.search()))
              }, null, 40, _hoisted_8), [
                [_vModelText, _ctx.sea.key]
              ]),
              (_ctx.sea.show)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "arrow arrow_up" }, null, -1)),
                    _createElementVNode("ul", _hoisted_10, [
                      (_ctx.sea.list.length>0)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sea.list, (v, k) => {
                            return (_openBlock(), _createElementBlock("li", {
                              class: "flex",
                              key: k,
                              onClick: ($event: any) => (_ctx.searchClick(v))
                            }, [
                              _createElementVNode("div", {
                                class: "img",
                                style: _normalizeStyle({backgroundImage: v.img?'url('+v.img+')':''})
                              }, [
                                (!v.img)
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_12))
                                  : _createCommentVNode("", true)
                              ], 4),
                              _createElementVNode("div", _hoisted_13, _toDisplayString(v.title), 1)
                            ], 8, _hoisted_11))
                          }), 128))
                        : (_openBlock(), _createElementBlock("li", _hoisted_14))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_15, [
              (_ctx.state.msg.list.length>0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.msg.list, (v, k) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: _normalizeClass(["flex", v.gid==_ctx.sendGid&&v.fid==_ctx.sendFid?'active':'']),
                        key: k,
                        onClick: ($event: any) => (_ctx.msgClick(v))
                      }, [
                        _createElementVNode("div", {
                          class: "img",
                          style: _normalizeStyle({backgroundImage: v.img?'url('+v.img+')':''})
                        }, [
                          (v.num>0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(v.num), 1))
                            : _createCommentVNode("", true),
                          (!v.img)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                            : _createCommentVNode("", true)
                        ], 4),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, _toDisplayString(v.title), 1),
                            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.getMsgDate(v.time)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_24, _toDisplayString(v.content), 1)
                        ])
                      ], 10, _hoisted_17))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.state.langs.msg_null), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.sendTitle), 1),
            _createElementVNode("div", _hoisted_28, [
              (_ctx.sendList.length>0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sendList, (v, k) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                      _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.getMsgTime(_ctx.sendList[k-1]?_ctx.sendList[k-1].time:v.time, v.time)), 1),
                      (v.fid!=_ctx.state.uinfo.uid)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            _createElementVNode("div", {
                              class: "img",
                              style: _normalizeStyle({backgroundImage: v.img?'url('+v.img+')':''})
                            }, [
                              (!v.img)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_31))
                                : _createCommentVNode("", true)
                            ], 4),
                            _createElementVNode("div", _hoisted_32, [
                              _createElementVNode("div", _hoisted_33, [
                                _cache[18] || (_cache[18] = _createElementVNode("span", { class: "arrow" }, null, -1)),
                                (v.loading)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_34))
                                  : _createCommentVNode("", true),
                                _createElementVNode("pre", null, _toDisplayString(v.content), 1)
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("div", _hoisted_37, [
                                _cache[19] || (_cache[19] = _createElementVNode("span", { class: "arrow" }, null, -1)),
                                (v.loading)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_38))
                                  : _createCommentVNode("", true),
                                _createElementVNode("pre", null, _toDisplayString(v.content), 1)
                              ])
                            ]),
                            _createElementVNode("div", {
                              class: "img",
                              style: _normalizeStyle({backgroundImage: v.img?'url('+v.img+')':''})
                            }, [
                              (!v.img)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_39))
                                : _createCommentVNode("", true)
                            ], 4)
                          ]))
                    ], 64))
                  }), 128))
                : (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(_ctx.state.langs.msg_null), 1)),
              _cache[20] || (_cache[20] = _createElementVNode("div", { id: "msgBottom" }, null, -1))
            ]),
            _cache[23] || (_cache[23] = _createElementVNode("ul", { class: "wm-msg_tools flex_flex" }, [
              _createElementVNode("li", null, [
                _createElementVNode("i", { class: "ui ui_folder" })
              ])
            ], -1)),
            _withDirectives(_createElementVNode("textarea", {
              class: "wm-msg_area scrollbar",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sendContent) = $event)),
              onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.msgInput())),
              onKeyup: _cache[8] || (_cache[8] = _withKeys(_withModifiers(($event: any) => (_ctx.msgCtrlEnter()), ["ctrl"]), ["enter"])),
              onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.msgSend && _ctx.msgSend(...args)), ["exact"]), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.sendContent]
            ]),
            _createElementVNode("div", _hoisted_41, [
              _cache[22] || (_cache[22] = _createElementVNode("span", null, "按下Ctrl+Enter换行", -1)),
              _createVNode(_component_wm_button, {
                padding: "0 32px",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.msgSend()))
              }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode("发送")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 4))
}